/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction, homeAction, shopAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

// Styles
import "./styles.scss";

// Toastify
import showErrMessage from "../../components/ShowErrMessage";
import PagePagination from "../../components/PagePagination";
import showProductMessage from "../../components/showProductMessage";

const Shop = () => {
  const dispatch = useDispatch();
  const productListData = useSelector((store) => store.home.productList);
  const categories = useSelector((store) => store.shop.categories);
  const shopAnnouncementList = useSelector(
    (store) => store.home.announcementList
  );
  const profileDetail = useSelector((store) => store.profile.profileDetail);

  const cartData = useSelector((store) => store.cart.cartData);
  const [cartProductDTOList, setCartProductDTOList] = useState(null);
  const [disableButtonList, setDisableButtonList] = useState([]);
  const [productList, setProductList] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(21);
  const [selectedType, setSelectedType] = useState("updateDate");
  const [selectedOrderBy, setSelectedOrderBy] = useState("DESC");
  const [filterScore, setFilterScore] = useState(false);
  const [selectedScore, setSelectedScore] = useState("0");

  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("0");
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("0");
  const [activeAccordion, setActiveAccordion] = useState(null);

  // Get Default Page - Api Calls
  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");
    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }

    // Api Calls
    dispatch(homeAction.getAnnouncementList("shop"));
    dispatch(homeAction.getProductList("updateDate", "DESC", selectedScore, pageSize, currentPage));
    dispatch(shopAction.getShopCategories("CATALOG"));
    dispatch(shopAction.getCart());
    return () => {};
  }, [dispatch]);

  // cartData - Render Control
  useEffect(() => {
    if (cartData) {
      let addedCartProducts = [];
      for (const i of cartData.cartProductDTOList) {
        addedCartProducts.push(i.product.id);
      }

      setDisableButtonList(addedCartProducts);
    }
    return () => {};
  }, [cartData]);

  // productListData - Render Control
  useEffect(() => {
    if (productListData) {
      setProductList(productListData);
      setTotalPages(productListData.totalPages);
      setPageSize(productListData.size);
    }
    return () => {};
  }, [productListData]);

  // cartData and productListData - Api Call Render Control
  useEffect(() => {
    if (cartData) {
      if (
        productListData &&
        productListData.content &&
        productListData.content.length > 0
      ) {
        for (const prod of productListData.content) {
          for (const item of cartData.cartProductDTOList) {
            if (prod.id === item.product.id) {
              prod.productCartCount = item.productCount;
            }
          }
        }

        setProductList(productListData);
      }
    }
    return () => {};
  }, [cartData, productListData]);

  // Add To Cart Function -- But I think we dont need this more...
  const addToCart = (e, id) => {
    e.preventDefault();

    const children = e.target.closest("div").children;
    let productCartCount = parseInt(
      e.target.closest("a").getAttribute("data-count")
    );

    if (children) {
      for (const i of children) {
        let clickedItem = e.target.closest("a");
        const addedId = i.getAttribute("data-added");
        if (parseInt(addedId) === id) {
          console.log("i: ", i);

          i.classList.remove("d-none");
          clickedItem.classList.add("d-none");

          setTimeout(() => {
            i.classList.add("d-none");
            clickedItem.classList.remove("d-none");
          }, 3500);
          //debugger;
        }
      }
    }

    productCartCount = productCartCount + 1;

    dispatch(homeAction.addToCart(id, productCartCount))
      .then((response) => {
        if (response.isHttpSuccess) {
          showProductMessage(`Ürün sepete eklendi!`);
          dispatch(shopAction.getCart());
          dispatch(homeAction.getProductList("updateDate", "DESC", selectedScore, pageSize, currentPage));
        }
      })
      .catch((err) => {
        if (err.isHttpError) {
          showErrMessage(err?.error?.response?.data?.errors[0]?.message);
        }
      });
  };

  // Filter - Header Product Order By - asc, desc, sold, updateDate
  const handleFilter = (e) => {
    if (e.target.value === "ASC" || e.target.value === "DESC") {
      setSelectedOrderBy(e.target.value);

      dispatch(
        homeAction.getProductList(
          "score",
          e.target.value,
          selectedScore,
          pageSize,
          0,
          selectedCategory,
          selectedSubCategory,
          0
        )
      );
      
    } else {
      setSelectedType(e.target.value);

      dispatch(
        homeAction.getProductList(
          e.target.value,
          selectedOrderBy,
          selectedScore,
          pageSize,
          0,
          selectedCategory,
          selectedSubCategory,
          0,
        )
      );
    }

    setCurrentPage(0);
  };

  // Filter Score
  const handleFilterScore = () => {
    setFilterScore(!filterScore);
  }

  useEffect(() => {
    if (filterScore) {    
      setSelectedScore(profileDetail?.score);
    } else {
      setSelectedScore("0");
    }

    return () => {};
  }, [filterScore]);

  useEffect(() => {
    if (selectedScore) {
      dispatch(
        homeAction.getProductList(selectedType, selectedOrderBy, selectedScore, pageSize, 0, selectedCategory, selectedSubCategory)
      );
    } else {
      dispatch(
        homeAction.getProductList(selectedType, selectedOrderBy, selectedScore, pageSize, 0, selectedCategory, selectedSubCategory)
      );
    }

    return () => {};
  }, [selectedScore]);

  // Handle Click SubCategory Event
  const handleClickSubCategoryItem = (
    e,
    subCategoryId,
    subCategoryName
  ) => {
    e.preventDefault();

    setSelectedSubCategory(subCategoryId);
    setSelectedSubCategoryName(subCategoryName);

    // Need Current Page = 0
    setCurrentPage(0);
    dispatch(
      homeAction.getProductList(selectedType, selectedOrderBy, selectedScore, pageSize, 0, selectedCategory, subCategoryId)
    );
  };

  // Pagination Change Event
  const handlePageChange = (page) => {
    setCurrentPage(page);

    dispatch(
      homeAction.getProductList(
        selectedType,
        selectedOrderBy,
        selectedScore,
        pageSize, 
        page,
        selectedCategory,
        selectedSubCategory,
        0
      )
    );
  };

  // Hanle Default Shop Function - Get All Products and Reset Filters
  const handleDefaultShop = (e) => {
    if (e) {
      e.preventDefault();
    }

    // Need Current Page = 0
    setCurrentPage(0);
    setSelectedCategory("0");
    setSelectedCategoryName(null);
    setSelectedSubCategory("0");
    setSelectedSubCategoryName(null);
    setActiveAccordion(null);
    setSelectedType("updateDate");
    setSelectedOrderBy("DESC");
    setFilterScore(false);
    setSelectedScore("0");

    dispatch(homeAction.getProductList(selectedType, selectedOrderBy, 0, pageSize, 0));
    dispatch(shopAction.getShopCategories("CATALOG"));
  }

  // Acordion Controller
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      callback && callback(eventKey);
  
      setSelectedCategory(eventKey);
      setSelectedCategoryName(children);
      setCurrentPage(0);
  
      setSelectedSubCategoryName("");
      setSelectedSubCategory("0");
  
      // isCurrentEventKey kontrolü
      if (currentEventKey === eventKey) {
        setActiveAccordion(null);
        
        // Tüm Ürünleri Al
        handleDefaultShop();
      } else {
        setActiveAccordion(eventKey);
        // Ana Kategoriye göre Ürünleri Getir
        dispatch(homeAction.getProductList("updateDate", "DESC", selectedScore, pageSize, 0, eventKey));
      }
    });
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button type="button" className="btn btn-link" onClick={decoratedOnClick}>
        {children}{" "}
        {isCurrentEventKey ? (
          <span className="accordion-button-item active"></span>
        ) : (
          <span className="accordion-button-item"></span>
        )}
      </button>
    );
  };  

  return (
    <>
      <Header />

      <main>
        <section className="bg-blue py-5 mb-sm-5 mb-3">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-4 opacity-50">
              <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
              <span className="me-3">»</span>
              <NavLink to="/shop" onClick={(e) => handleDefaultShop()} className="text-white text-decoration-none me-3">Dükkan</NavLink>
              <span className="me-3">»</span>
              
              {selectedCategoryName ? 
                <span className="me-3">{selectedCategoryName}</span> : <span>Tüm Ürünler</span>
              }

              {selectedCategoryName && selectedSubCategoryName &&
                <>
                  <span className="me-3">»</span>
                  <span className="me-3">{selectedSubCategoryName}</span>
                </>
              }
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h1 className="mb-0 text-white">Dükkan</h1>
              <div className="form-select-custom ms-3">
                <select
                  className="form-select w-auto border-0 text-end bg-transparent text-white p-0 text-truncate"
                  onChange={(e) => handleFilter(e)}
                >
                  <option value="updateDate">En Yeniler</option>
                  <option value="sold">Çok Satanlar</option>
                  <option value="ASC">Fiyata Göre Artan</option>
                  <option value="DESC">Fiyata Göre Azalan</option>
                </select>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-sm-10 mb-5">
          <div className="container">
            <div className="row gy-0">
              <div className="col-xl-3 col-lg-4">
                <div className="pe-lg-4 pe-0">
                  <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title">Kategoriler</h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className="bg-orange rounded-3 p-4 mb-5">
                        {profileDetail?.score && profileDetail?.score > 0 ? 
                          <h1 className="text-white text-center mb-3">{profileDetail?.score}</h1> : 
                          <h1 className="text-white text-center mb-3">0</h1>
                        }
                        
                        <div className="form-check form-switch d-flex align-items-center ps-0">
                          <label
                            className="form-check-label small me-2 text-black opacity-50"
                            htmlFor="filterScore"
                          >
                            Puan ile alabileceğim ürünleri görüntüle{" "}
                          </label>
                          <input
                            className="form-check-input flex-shrink-0 float-none m-0"
                            type="checkbox"
                            id="filterScore"
                            onChange={() => handleFilterScore()}
                          />
                        </div>
                      </div>

                      <Accordion activeKey={activeAccordion}>
                        <Card className="active">
                          <Card.Header>
                            <a href="#" onClick={(e) => handleDefaultShop(e)} className={selectedCategory === "0" && selectedSubCategory === "0" ? "btn btn-link text-decoration-none active" : "btn btn-link text-decoration-none"}>Tüm Ürünler</a>
                          </Card.Header>
                        </Card>

                        {categories && categories.length > 0
                          ? categories.map((item) => {
                              return (
                                <Card key={item.id}>
                                  <Card.Header>
                                    <ContextAwareToggle eventKey={item.id}>
                                      {item.name}
                                    </ContextAwareToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={item.id}>
                                    <Card.Body>
                                      <ul
                                        className="accordion-sub-menu"
                                        style={{ display: "none" }}
                                      >
                                        {item.subCategoryList &&
                                        item.subCategoryList.length > 0
                                          ? item.subCategoryList.map(
                                              (subItem, index) => {
                                                return (
                                                  <li key={index}>
                                                    <a
                                                      href="#"
                                                      data-id={item.id}
                                                      data-subid={subItem.id}
                                                      onClick={(e) =>
                                                        handleClickSubCategoryItem(
                                                          e,
                                                          subItem.id,
                                                          subItem.name
                                                        )
                                                      }
                                                      className={subItem?.id === selectedSubCategory ? "text-green" : ""}
                                                    >
                                                      {subItem.name}
                                                    </a>
                                                  </li>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              );
                            })
                          : null}
                      </Accordion>
                    </div>
                  </div>
                  <a
                    href=".offcanvas"
                    className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3"
                    data-bs-toggle="offcanvas"
                  >
                    <i className="fas fa-filter"></i>
                  </a>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8">
                <div className="row g-sm-4 g-2">

                  {productList && productList.totalElements > 0
                    ? productList.content.map((item, index) => {
                      return (
                        <div className="col-xl-4 col-6"  key={item.id} id={item.id + "" + index}>
                          <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column position-relative">
                            {item?.topIcon && item?.topIconText &&
                              <span className="rounded-pill bg-orange text-white small position-absolute z-index-top top-0 start-0 px-3 py-1 m-sm-3 m-2">{item?.topIconText}</span>
                            }

                            {item.productContentUrlList &&
                              item.productContentUrlList.length > 0 && item.name ? 
                                <img
                                  src={
                                    item.productContentUrlList[0]
                                      .baseAddress
                                  }
                                  alt={item.name}
                                  className="img-contain w-100"
                                  style={{ height: '200px', margin: '20px 0' }}
                                />
                                : 
                                <div style={{ height: "200px", background: "#000" }}>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/assets/img/logo.svg"
                                    }
                                    alt=""
                                    className="w-100 opacity-50"
                                    style={{
                                      width: "310px",
                                      height: "200px",
                                      objectFit: "scale-down",
                                    }}
                                  />
                                </div>
                            }

                            <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                              {item.stock && 
                                <span className="d-block small text-blue">
                                  {item.stock} stok
                                </span>
                              }

                              {item.name && 
                                <h5 className="fw-normal lh-base mb-2">
                                  {item.name}
                                </h5>
                              }
                              
                              {item.score && 
                                <span className="d-block small text-blue mb-4">
                                  {item.score} <FontAwesomeIcon icon={faTrophy} />
                                </span>
                              }

                              <Link
                                to={`/shop/${item.id}`}
                                // onClick={(e) => addToCart(e, item.id)}
                                data-id={`${item.id}`}
                                data-count={
                                  item.productCartCount
                                    ? item.productCartCount
                                    : "0"
                                }
                                className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto add-to-cart-default"
                              >
                                <span>İncele</span>
                                <i className="fas fa-arrow-right ms-2 d-sm-inline-block d-none"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )
                    }) : null}
                </div>

                <PagePagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />

              </div>
            </div>
          </div>
        </section>
      </main>
      
      <Footer />
    </>
  );
};

export default Shop;
