import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userAction, gamesAction } from "../../redux/modules";

// Components
import GlobalLoader from "../../components/GlobalLoader";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Styles
import "./styles.scss";

// Images
import BgDots from "../../assets/images/games/bg-pattern.png";

// Containers
import GameBoard from "./GameBoard";

const GameFindMate = () => {
  const dispatch = useDispatch();

  const findMate = useSelector((store) => store.games.findMate);
  const findMateControl = useSelector((store) => store.games.findMateControl);

  // Custom Styles
  const [customStyles, setCustomStyles] = useState({
    backgroundImage:
      "radial-gradient(circle at 50% 36%, #27aae1, #393190 122%)",
  });

  // Find Mate Control Items
  const [showGame, setShowGame] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [activeGame, setActiveGame] = useState(null);

  // Items Id List
  const [cardList, setCardList] = useState(null);

  // BrandDTOList
  const [brandDTOList, setBrandDTOList] = useState(null);

  // Page Check Login - Logout
  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }

    return () => {};
  }, []);

  // Find Mate - Api Call
  useEffect(() => {
    dispatch(gamesAction.getFindMate())
      .then((res) => {
        const cardList = [];

        for (const element of res.data.brandStyleDTOList) {
          // This Row is True - Don't remove!
          cardList.push({ id: element.id, icon: element.icon });
          cardList.push({ id: element.id, icon: element.icon });
        }

        setActiveGame(res.data.id);
        setCardList(cardList);
        setBrandDTOList(res.data.brandDTOList);
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    dispatch(gamesAction.getFindMateControl());
    return () => {};
  }, [dispatch]);

  // Find Mate - Bg Added
  useEffect(() => {
    if (findMate?.backgroundPattern) {
      setCustomStyles((prevStyles) => ({
        ...prevStyles,
        background: findMate.backgroundPattern,
      }));
    }
  }, [findMate]);

  // Find Mate - Control
  useEffect(() => {
    if (findMateControl && findMateControl.gameFinished) {
      setGameFinished(true);
    } else {
      setGameFinished(false);
    }

    return () => {};
  }, [findMateControl]);

  const handleBeginGame = (e) => {
    e.preventDefault();

    // Start The Game
    setShowGame(true);
  };

  return (
    <>
      <main className="main-container">
        <Header />
        <section
          className="games-page page-container find-mate-page"
          id="backgroundPattern"
          style={customStyles}
        >
          <div
            className="games-page-introduction"
            style={{ backgroundImage: `url(${BgDots})` }}
            id="backgroundImage"
          >
            <Container>
              {showGame === false ? (
                <Row className="justify-content-md-center text-center text-white">
                  <Col xs="12" sm="12" md="8" lg="6" xl="4">
                    {findMate && findMate.icon && findMate.title && (
                      <div className="games-page-circle">
                        <img src={findMate.icon} alt={findMate.title} />
                      </div>
                    )}

                    {findMate && findMate.title && (
                      <h1 className="mt-5 mb-0 p-0">{findMate.title}</h1>
                    )}

                    {findMate && findMate.description && !gameFinished && (
                      <p className="mt-3 mb-0">{findMate.description}</p>
                    )}
                    
                    {findMate ? (
                      <>
                        {gameFinished ? (
                          <div className="mt-3">
                            Daha önceden yarışmaya katıldınız. Lütfen daha sonra
                            tekrar deneyiniz.
                          </div>
                        ) : (
                          <>
                            {findMate && (
                              <button
                                onClick={(e) => handleBeginGame(e)}
                                className="btn btn-success text-white mt-5 mb-0"
                              >
                                {findMate.buttonText}
                              </button>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <GlobalLoader isLoading={true} color={"#7CFC00"} position={"absolute"} />
                    )}
                  </Col>
                </Row>
              ) : (
                <>
                  {isFinish === false && (
                    <div className="text-center text-white">
                      {findMate && findMate.title && (
                        <h1 className="mt-5 mb-0 p-0">{findMate.title}</h1>
                      )}

                      {findMate && findMate.description && !showGame && (
                        <p className="mt-3 mb-0">{findMate.description}</p>
                      )}

                      {findMate && findMate.inGameDescription && showGame && (
                        <p className="mt-3 mb-0">
                          {findMate.inGameDescription}
                        </p>
                      )}
                    </div>
                  )}

                  <GameBoard
                    cardList={cardList}
                    brandDTOList={brandDTOList}
                    activeGame={activeGame}
                    setIsFinish={setIsFinish}
                  />
                </>
              )}
            </Container>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default GameFindMate;
