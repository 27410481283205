/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { brandAction, userAction } from "../../redux/modules";

// Header Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

// import Swiper core and required modules
import { Navigation, Pagination, Zoom, FreeMode, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

// Styles
import "./styles.scss";

const Brands = () => {
  const dispatch = useDispatch();
  const brandList = useSelector((store) => store.brand.brandList);
  const brandStyleList = useSelector((store) => store.brand.brandStyleList);
  const brandStyleActive = useSelector((store) => store.brand.brandStyleActive);
  const brandCategoryList = useSelector(
    (store) => store.brand.brandCategoryList
  );

  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [filteredBrandStyle, setFilteredBrandStyle] = useState(null);

  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }

    // Get All Brand List
    dispatch(brandAction.getBrandList());

    // Get All Brand Style List
    dispatch(brandAction.getBrandStyleList()).then((res) => {
      if (res.isHttpSuccess) {
        setFilteredBrandStyle(res.data);
      }
    });

    // Get All Brand Category List
    dispatch(brandAction.getBrandCategoryList());

    return () => {};
  }, []);

  useEffect(() => {
    if (brandStyleList.totalElements > 0 && (brandStyleActive?.totalElements === 0 || !brandStyleActive)) {
      setFilteredBrandStyle(brandStyleList);
    }
    return () => {};
  }, [brandStyleList]);

  useEffect(() => {
    if (brandStyleActive) {
      setFilteredBrandStyle(brandStyleActive);

      console.log("brandStyleActive: ", brandStyleActive);
    }
    return () => {};
  }, [brandStyleActive]);

  // Category Accordion
  function ContextAwareToggle({ children, eventKey, callback, hasSubCategories, showAll }) {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
  
    return (
      <button type="button" className={isCurrentEventKey || showAll ? "btn btn-link active" : "btn btn-link"} onClick={decoratedOnClick}>
        {children}{" "}
        {hasSubCategories && (
          <>
            {isCurrentEventKey ? (
              <span className="accordion-button-item active"></span>
            ) : (
              <span className="accordion-button-item"></span>
            )}
          </>
        )}
      </button>
    );
  }

  const handleClickGetBrandProducts = (e, selectedBrand) => {
    e.preventDefault();

    setSelectedBrand(selectedBrand);

    dispatch(brandAction.getBrandStyleList(0, 2000, 'name,asc', selectedBrand.id)).then((res) => {
      if (res.isHttpSuccess) {
        setFilteredBrandStyle(res.data);

        // Brand Category List By Id Filter
        dispatch(brandAction.getBrandCategoryList(selectedBrand.id));
      }
    });
  };

  const handleShowProducts = (e, subItem) => {
    e.preventDefault();
    setActiveCategory(subItem);
    dispatch(brandAction.getBrandSubCategoryStyleList(subItem.id));
  }

  const handleGetAllBrandStyleList = (selectedBrand) => {
    if (selectedBrand?.id) {
      // Selected Brand's BrandStyle List
      dispatch(brandAction.getBrandStyleList(0, 2000, 'name,asc', selectedBrand?.id)).then((res) => {
        if (res.isHttpSuccess) {
          setFilteredBrandStyle(res.data);
  
          // Brand Category List By Id Filter
          dispatch(brandAction.getBrandCategoryList(selectedBrand?.id));
        }
      });
    } else {
      // Get All Brand Style List
      dispatch(brandAction.getBrandStyleList(0, 2000, 'name,asc')).then((res) => {
        if (res.isHttpSuccess) {
          setFilteredBrandStyle(res.data);
        }
      });
    }
    
    if (activeCategory) {
      setActiveCategory(null);
    }
  }

  const handleClearBrandFilter = (e) => {
    e.preventDefault();

    setSelectedBrand(null);

    // Get All Brand Style List
    dispatch(brandAction.getBrandStyleList()).then((res) => {
      if (res.isHttpSuccess) {
        setFilteredBrandStyle(res.data);
      }
    });

    // Get All Brand Category List
    dispatch(brandAction.getBrandCategoryList());

    // Close Accordion
    const showCollapse = document.querySelector('.brand-page-categories .collapse.show');
    if (showCollapse) {
      showCollapse.previousElementSibling.querySelector('.btn').click();
    }
  }

  return (
    <>
      <Header />

      <main>
        <section className="bg-blue py-5 mb-sm-5 mb-3">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-4 opacity-50">
              <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
              <span className="me-3">»</span>
              <NavLink to="/brands" className="text-white text-decoration-none me-3">Ürünlerimiz</NavLink>
              {selectedBrand && (
                <>
                  <span className="me-3">»</span>
                  <NavLink to={`/brands/${selectedBrand.id}`} className="text-white text-decoration-none me-3">{selectedBrand.name}</NavLink>
                </>
              )}
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <h1 className="mb-0 text-white">Ürünlerimiz</h1>
            </div>
          </div>
        </section>

        <section className="mb-sm-10 mb-5">
          <div className="container">
            <div className={"row mb-5"}>
              <div className="col-12">
                <Swiper
                  modules={[Navigation, Pagination, Zoom, FreeMode, Thumbs]}
                  slidesPerView={1}
                  centeredSlides={false}
                  slidesPerGroupSkip={1}
                  grabCursor={true}
                  keyboard={{
                    enabled: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      slidesPerGroup: 1,
                      slidesPerGroupSkip: 1,
                    },

                    360: {
                      slidesPerView: 2,
                      slidesPerGroup: 1,
                      slidesPerGroupSkip: 1,
                    },

                    768: {
                      slidesPerView: 3,
                      slidesPerGroup: 1,
                      slidesPerGroupSkip: 1,
                    },

                    992: {
                      slidesPerView: 4,
                      slidesPerGroup: 1,
                      slidesPerGroupSkip: 1,
                    },

                    1440: {
                      slidesPerView: 5,
                      slidesPerGroup: 1,
                      slidesPerGroupSkip: 1,
                    },
                  }}
                  scrollbar={false}
                  navigation={true}
                  className="products home-brand"
                >
                  {brandList && brandList.totalElements > 0
                    && brandList.content.map((item, index) => {
                      return (
                        <SwiperSlide key={"22" + item.id} className={item.id === selectedBrand?.id ? "brand-slider-slide active" : "brand-slider-slide"}>
                          <div 
                            className="col"
                            onClick={(e) =>
                              handleClickGetBrandProducts(
                                e,
                                item
                              )
                            }
                          >
                            <div className={selectedBrand?.id === item.id ? "product-brand-item active" : "product-brand-item"}>
                              <div className="product-brand-item-img-wrapper cursor-pointer">
                                {item.icon ? (
                                  <div className="global-grid-item-image-wrapper">
                                    <img
                                      src={item.icon}
                                      alt={item.name ? item.name : null}
                                      className="global-grid-item-image img-fluid"
                                    />
                                  </div>
                                ) : (
                                  <div style={{ background: "#000", width: '100%' }}>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + "/assets/img/logo.svg"
                                      }
                                      alt=""
                                      className="w-100 opacity-50"
                                      style={{
                                        width: "300px",
                                        height: "200px",
                                        objectFit: "scale-down",
                                        margin: '20px 0'
                                      }}
                                    />
                                  </div>
                                )}
                              </div>

                              <div className="product-brand-item-none-hover">
                                {item.name &&
                                  <h3>{item.name}</h3>
                                }
                              </div>

                              <div className="product-brand-item-hover">
                                <a
                                  className="btn btn-success"
                                  onClick={(e) =>
                                    handleClickGetBrandProducts(
                                      e,
                                      item
                                    )
                                  }
                                  style={{ marginRight: '10px' }}
                                >
                                  Ürünler
                                </a>

                                <NavLink
                                  className="btn btn-blue text-white"
                                  to={{
                                    pathname: `/brands/${item.id}`,
                                  }}
                                >
                                  Detay
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>

            {selectedBrand && 
              <div className="row">
                <div className="col">
                  <div className="text-center mb-5">
                    <a 
                      href="#" 
                      className="text-white btn btn-red clear-brand-filter text-decoration-none"
                      onClick={(e) => handleClearBrandFilter(e)}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="active"
                      />
                      <span className="mx-2">Marka Filtresini Kaldır</span>
                    </a>
                  </div>
                </div>
              </div>
            }
            
            <div className="row gy-0">
              <div className="col-xl-3 col-lg-4">
                <div className="pe-lg-4 pe-0">
                  <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title">Kategoriler</h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      />
                    </div>

                    <div className="offcanvas-body">
                      <Accordion>
                        <Card className="active">
                          <Card.Header onClick={() => handleGetAllBrandStyleList(selectedBrand?.id)}>
                            <ContextAwareToggle
                              hasSubCategories={false}
                              showAll={activeCategory ? false : true}
                            >
                              Tüm Marka Ürünleri
                            </ContextAwareToggle>
                          </Card.Header>
                        </Card>

                        {brandCategoryList && brandCategoryList.length > 0
                          ? brandCategoryList.map((item) => {
                              const hasSubCategories =
                                item.brandSubCategoryDTOList && item.brandSubCategoryDTOList.length > 0;

                              return (
                                <Card key={item.id}>
                                  <Card.Header>
                                    <ContextAwareToggle
                                      eventKey={item.id}
                                      hasSubCategories={hasSubCategories}
                                    >
                                      {item.name}
                                    </ContextAwareToggle>
                                  </Card.Header>
                                  {hasSubCategories && (
                                    <Accordion.Collapse eventKey={item.id}>
                                      <Card.Body>
                                        <ul className="accordion-sub-menu" style={{ display: "none" }}>
                                          {item.brandSubCategoryDTOList.map((subItem) => (
                                            <li key={subItem.id} id={subItem.id}>
                                              <a href="#" onClick={(e) => handleShowProducts(e, subItem)} className={activeCategory?.id === subItem.id ? "active" : ""}>{subItem.name}</a>
                                            </li>
                                          ))}
                                        </ul>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  )}
                                </Card>
                              );
                            })
                          : null}
                      </Accordion>
                    </div>
                  </div>

                  <a
                    href=".offcanvas"
                    className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3"
                    data-bs-toggle="offcanvas"
                  >
                    <i className="fas fa-filter" />
                  </a>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8">
                <div className="row g-sm-4 g-2">
                  {filteredBrandStyle && filteredBrandStyle.totalElements > 0
                    ? filteredBrandStyle.content.map((item) => {
                        return (
                          <div className="col-xl-4 col-6" key={item.id}>
                            <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column position-relative">
                              {item?.topIcon && item?.topIconText &&
                                <span className="rounded-pill bg-orange text-white small position-absolute z-index-top top-0 start-0 px-3 py-1 m-sm-3 m-2">{item?.topIconText}</span>
                              }

                              {item.brandStyleContentDTOList?.length > 0 ? (
                                <img
                                  src={item.brandStyleContentDTOList[0]?.baseAddress}
                                  alt={item.name ? item.name : null}
                                  className="img-contain w-100"
                                  style={{ height: '200px', margin: '20px 0' }}
                                />
                              ) : (
                                <div style={{ background: "#000", width: '100%' }}>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/assets/img/logo.svg"
                                    }
                                    alt=""
                                    className="w-100 opacity-50"
                                    style={{
                                      width: "300px",
                                      height: "200px",
                                      objectFit: "scale-down",
                                    }}
                                  />
                                </div>
                              )}

                              <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                                {item.name && 
                                  <h5 className="fw-normal lh-base mb-2">
                                    {item.name}
                                  </h5>
                                }
                                
                                {item.price && 
                                  <span className="d-block small text-blue mb-4">
                                    {item.price} ₺
                                    {/* <s className="me-2">29,90 ₺</s> {item.price} ₺ */}
                                  </span>
                                }
                                <NavLink
                                  className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto"
                                  to={{
                                    pathname: `/brand-product-detail/${item.brandSubCategoryId}/${item.id}`,
                                  }}
                                >
                                  İncele
                                  <i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none" />
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : <div>Bu marka kategorisinde gösterilecek ürün bulunamadı!</div>}
                </div>

                {/* Pagination */}
                {/* <nav className="mt-sm-8 mt-5">
                  <ul className="pagination pagination-lg justify-content-center mb-0">
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <span>«</span>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link bg-blue" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <span>»</span>
                      </a>
                    </li>
                  </ul>
                </nav> */}
                {/* End Pagination */}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Brands;
