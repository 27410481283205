/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction, gamesAction, profileAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// Styles
import "./styles.scss";

// Images
import BgDots from "../../assets/images/games/bg-pattern.png";
import Girls from "../../assets/images/games/lucky-wheel/girls.png";

// CountdownCircleTimer
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// Toastify
import showMessage from "../../components/ShowMessage";
import showErrMessage from "../../components/ShowErrMessage";

import ScoreHuntBanner from "../../assets/images/games/score-hunt/score-hunt-banner.png";

const GameScoreHunt = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Game Score Hunt
  const scoreHunt = useSelector((store) => store.games.scoreHunt);

  // Game Score Hunt Control
  const scoreHuntControl = useSelector((store) => store.games.scoreHuntControl);
  const scoreHuntStart = useSelector((store) => store.games.scoreHuntStart);

  const [scoreHuntStartState, setScoreHuntStartState] = useState(null);

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // First Step Game
  const [beginGame, setBeginGame] = useState(false);

  // Second Step Game
  const [gameStarted, setGameStarted] = useState(false);

  // Game is Finish?
  const [isFinish, setIsFinish] = useState(false);

  // Response Message
  const [responseMessage, setResponseMessage] = useState("");
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  // Timer Defaults
  const [timerColors, setTimerColors] = useState(["#76e17b", "#F7B801"]);
  const [timerColorsTime, setTimerColorsTime] = useState([90, 0]);
  const [timerDuration, setTimerDuration] = useState(0);

  const [btnDisabled, setBtnDisabled] = useState(false);

  const [point, setPoint] = useState(0);

  // CountdownTimer When Complete
  const handleTimerComplete = () => {
    dispatch(gamesAction.scoreHuntAnswer(scoreHunt?.id))
      .then(() => {
        // setGameStarted(false);
        setBeginGame(false);
        setIsTimeout(true);
        setIsFinish(true);
      })
      .catch(() => {
        // setGameStarted(false);
        setBeginGame(false);
        setIsTimeout(true);
        setIsFinish(true);
      });
  };

  const handleBeginGame = () => {
    if (scoreHunt) {
      // Start Score Hunt
      dispatch(gamesAction.scoreHuntStart())
        .then((res) => {
          if (res.isHttpSuccess) {
            setGameStarted(true);
            setScoreHuntStartState(res.data);
          }
        })
        .catch((err) => {
          // Error
          if (err.isHttpError) {
            setGameStarted(false);
            console.log(err.isHttpError);
          }
        });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setBtnDisabled(true);

    if (selectedAnswer) {
      dispatch(gamesAction.scoreHuntAnswer(scoreHunt?.id, selectedAnswer))
        .then((res) => {
          // Correct Answer
          if (res.isHttpSuccess) {
            // Timer = 0
            setTimerDuration(0);

            let message = res.data.responseText;
            message = message.replace('{point}', res.data.score);
            setResponseMessage(message);
            setPoint(res.data.score);

            setIsFinish(true);
            setTimeout(() => {
              dispatch(profileAction.getLoginUserDetail());
            }, 2000);

            if (res.data.answerTrue === true) {
              setIsCorrectAnswer(true);
            } else if (res.data.answerFalse === true) {
              setIsCorrectAnswer(false);
            } else if (res.data.answerTimeOut === true) {
              setIsTimeout(true);
            }
          }
        });
    } else {
      showErrMessage("Lütfen bir seçenek seçiniz!");
      setBtnDisabled(false);
    }
  };

  // Page Check Login - Logout
  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }

    // Get Default Datas
    dispatch(gamesAction.scoreHunt());
    dispatch(gamesAction.scoreHuntControl()).then((res) => {
      if (
        (res.data?.remainTime > 0 && res.data.gameIsOn) ||
        location?.state?.fromAboutPage
      ) {
        setBeginGame(true);
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (beginGame) {
      handleBeginGame();
    }
  }, [beginGame]);

  // Defaults
  useEffect(() => {
    if (scoreHuntControl) {
      // Timer
      setTimerDuration(scoreHuntControl?.remainTime);

      // Timer Colors
      setTimerColorsTime([scoreHuntControl?.remainTime, 0]);
    }
  }, [scoreHuntControl]);

  const handleRedirect = () => {
    showMessage("Yarışma için ürün sayfasına yönlendirildiniz. Ürün detaylarında Puan Avı ikonunu bulmalısınız!");
  }

  return (
    <>
      <main className="main-container game-score-hunt">
        <Header />
        <section
          className="games-page page-container wheel-page score-hunt-page"
          id="backgroundPattern"
          style={{ backgroundImage: scoreHunt?.backgroundPattern }}
        >
          <div
            className="games-page-introduction"
            style={{ backgroundImage: `url(${BgDots})` }}
            id="backgroundImage"
          >
            <div
              className={
                gameStarted && scoreHuntControl?.remainTime > 0 && isFinish === false
                  ? "d-block game-timer"
                  : "d-none game-timer"
              }
            >
              <h3 className="text-center mb-3">Kalan Süre</h3>
              <div className="game-countdown text-center ml-auto mr-auto">
                {scoreHunt?.remainTime && 
                  timerDuration && (
                  <CountdownCircleTimer
                    isPlaying
                    duration={scoreHunt?.remainTime}
                    initialRemainingTime={timerDuration}
                    colors={timerColors}
                    colorsTime={timerColorsTime}
                    size={80}
                    strokeWidth={8}
                    onComplete={() => handleTimerComplete()}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                )}
              </div>
              <h3>Cevap Hakkı</h3>
              <p className="text-center">1</p>
            </div>

            <Container>
              <Row className="justify-content-md-center text-center text-white">
                {scoreHuntControl && (
                  <>
                    {scoreHuntControl?.remainTime > 0 ? (
                      <>
                        {beginGame === false ? (
                          <>
                            {/* Score Hunt Entrance */}
                            <Col xs="12" sm="12" md="8" lg="6" xl="4">
                              <div className="d-flex flex-column justify-content-center align-items-center wrapper-wp-start">
                                <div className="games-page-circle mb-md-5 mb-3">
                                  {scoreHunt?.icon ? (
                                    <img
                                      src={scoreHunt?.icon}
                                      alt={scoreHunt?.title}
                                      className="w-[100px]"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      style={{ width: "100px" }}
                                    >
                                      {/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                                      <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                                    </svg>
                                  )}
                                </div>
                                <h2 className="mb-3 mb-md-4">
                                  {scoreHunt?.title}
                                </h2>
                                <p>{scoreHunt?.description}</p>

                                <Link
                                  to={`/brands`}
                                  className="btn btn-success mt-5 mb-0 text-white"
                                  onClick={() => handleRedirect()}
                                >
                                  {scoreHunt?.buttonText}
                                </Link>
                              </div>
                            </Col>
                            {/* End Score Hunt Entrance */}
                          </>
                        ) : (
                          <Col>
                            {gameStarted && isFinish === false && (
                              <div className="wrapper-question-start">
                                {/* Question */}
                                <div className="wrapper-question">
                                  <Form onSubmit={handleFormSubmit}>
                                    <Form.Group as={Row}>
                                      {scoreHuntStartState?.questionDescription ? (
                                        <Form.Label
                                          as="legend"
                                          column
                                          sm={12}
                                          style={{
                                            fontSize: "24px",
                                            padding: "0",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          {
                                            scoreHuntStartState?.questionDescription
                                          }
                                        </Form.Label>
                                      ) : (
                                        <Form.Label
                                          as="legend"
                                          column
                                          sm={12}
                                          style={{
                                            fontSize: "24px",
                                            padding: "0",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          {scoreHuntStart?.questionDescription}
                                        </Form.Label>
                                      )}

                                      <Col sm={12} className="mb-3">
                                        <div className="wrapper-answer-options">
                                          {scoreHuntStartState
                                            ?.scoreHuntChoiceDTOList?.length >
                                            0 &&
                                            scoreHuntStartState?.scoreHuntChoiceDTOList?.map(
                                              (answer, aIndex) => {
                                                return (
                                                  <Form.Check
                                                    key={aIndex}
                                                    type="radio"
                                                    label={answer.description}
                                                    name={"answer"}
                                                    value={answer.id}
                                                    id={answer.id}
                                                    onClick={() =>
                                                      setSelectedAnswer(
                                                        answer.id
                                                      )
                                                    }
                                                  />
                                                );
                                              }
                                            )}

                                          {!scoreHuntStartState &&
                                            scoreHuntStart
                                              ?.scoreHuntChoiceDTOList?.length >
                                              0 &&
                                            scoreHuntStart?.scoreHuntChoiceDTOList?.map(
                                              (answer, aIndex) => {
                                                return (
                                                  <Form.Check
                                                    key={aIndex}
                                                    type="radio"
                                                    label={answer.description}
                                                    name={"answer"}
                                                    value={answer.id}
                                                    id={answer.id}
                                                    onClick={() =>
                                                      setSelectedAnswer(
                                                        answer.id
                                                      )
                                                    }
                                                  />
                                                );
                                              }
                                            )}
                                        </div>
                                      </Col>
                                    </Form.Group>

                                    <Button
                                      variant="primary"
                                      className="btn btn-success text-white"
                                      type="submit"
                                      disabled={btnDisabled}
                                    >
                                      Cevapla
                                    </Button>
                                  </Form>
                                </div>
                                {/* End Question && Answers */}
                              </div>
                            )}

                            {isFinish === true && (
                              <Row className="justify-content-md-center">
                                {isTimeout === true ? (
                                  <>
                                    <h1>Zamanında Cevaplayamadınız!</h1>
                                    {responseMessage && (
                                      <p>{responseMessage}</p>
                                    )}
                                    <div className="wrapper-reward active">
                                      <img src={Girls} alt="" />
                                      <h4>0</h4>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {isCorrectAnswer === true ? (
                                      <Col
                                        xs={12}
                                        className="text-center find-mate-win active"
                                      >
                                        <h1>Tebrikler Kazandınız!</h1>
                                        <p className="!m-0">
                                          {responseMessage}
                                        </p>

                                        <div className="wrapper-reward active">
                                          <img src={Girls} alt="" />
                                          <h4>{point}</h4>
                                        </div>
                                      </Col>
                                    ) : (
                                      <Col
                                        xs={12}
                                        className="text-center find-mate-win active"
                                      >
                                        <h1>Kaybettiniz!</h1>
                                        <p className="!m-0">
                                          {responseMessage}
                                        </p>

                                        <div className="wrapper-reward active">
                                          <img src={Girls} alt="" />
                                          <h4>0</h4>
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}
                              </Row>
                            )}
                          </Col>
                        )}
                      </>
                    ) : (
                      <div className="flex flex-column">
                        <div>
                          {/* Score Hunt Entrance */}
                          <div className="d-flex flex-column justify-content-center align-items-center wrapper-wp-start">
                            <div className="games-page-circle mb-md-5 mb-3">
                              {scoreHunt?.icon && (
                                <img
                                  src={scoreHunt?.icon}
                                  alt={scoreHunt?.title}
                                />
                              )}
                            </div>
                            <h2 className="m-0">{scoreHunt?.title}</h2>
                          </div>
                          {/* End Score Hunt Entrance */}

                          {/* Score Hunt Manipulated */}
                          <div className="mt-3">
                            Daha önceden yarışmaya katıldınız. Lütfen daha sonra
                            tekrar deneyiniz.
                          </div>
                          {/* End Score Hunt Manipulated */}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Row>
            </Container>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default GameScoreHunt;
