import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesAction, profileAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// CountdownCircleTimer
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import Roulette from "../../assets/sounds/roulette.mp3";

// Styles
import "./styles.scss";

// Wheel
import { Wheel } from "react-custom-roulette";

// Images
import BgDots from "../../assets/images/games/bg-pattern.png";
import Girls from "../../assets/images/games/lucky-wheel/girls.png";
import Banner from "../../assets/images/games/lucky-wheel/luckywheel-banner.png";
import Triangle from "../../assets/images/games/lucky-wheel/triangle-106.png";

const GameQuestionWheel = () => {
  const dispatch = useDispatch();
  const luckyWheel = useSelector((store) => store.games.luckyWheel);
  const luckyWheelControl = useSelector(
    (store) => store.games.luckyWheelControl
  );
  const luckyWheelStart = useSelector((store) => store.games.luckyWheelStart);

  const [luckyWheelError, setLuckyWheelError] = useState(false);

  const [hasQuestion, setHasQuestion] = useState(false);
  const [question, setQuestion] = useState([]);

  const [beginGame, setBeginGame] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  const [spinned, setSpinned] = useState(false);
  const [wheelData, setWheelData] = useState([]);
  const [spinDuration, setSpinDuration] = useState(1);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [startIndex, setStartIndex] = useState(0);

  // Game is Finish?
  const [isFinish, setIsFinish] = useState(false);

  // Response Message
  const [responseMessage, setResponseMessage] = useState("");
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  // Timer Defaults
  const [timerColors] = useState(["#76e17b", "#F7B801"]);
  const [timerColorsTime] = useState([90, 0]);

  const [audio, setAudio] = useState(null);

  // Wheel Win
  const [wheelWin, setWheelWin] = useState(null);

  // Question Wheel Win
  const [questionWheelWin, setQuestionWheelWin] = useState(null);
  const [answered, setAnswered] = useState(false);

  // Question Error
  const [questionError, setQuestionError] = useState(false);

  // Right of Answer
  const [rightOfAnswer, setRightOfAnswer] = useState(1);

  const [point, setPoint] = useState(0);

  // Lucky Wheel When Spin
  const handleSpinClick = () => {
    if (wheelData) {
      // Disabled Spinning
      setMustSpin(true);
      setSpinned(true);

      audio.play();
    }
  };

  // CountdownTimer When Complete
  const handleTimerComplete = () => {
    // Disabled Spinning
    setSpinned(true);
  };

  const handleStartGame = () => {
    // Lucky Wheel Start
    dispatch(gamesAction.getLuckyWheelStart()).then((res) => {
      if (res.data && res.data.wheelPartDTOList) {
        let arr = [];

        res.data.wheelPartDTOList.forEach((element, index) => {
          if (
            element.luckyWheelQuestionDTOList &&
            element.luckyWheelQuestionDTOList.length > 0
          ) {
            // Has Question or Not
            setHasQuestion(true);

            // Prize Number's Question Added Here
            if (parseInt(prizeNumber) === index) {
              setQuestion([element]);
            }

            // Lucky Wheel Stylesheet Added Here
            arr.push({
              option: element.text,
              style: {
                backgroundColor: element.color,
                textColor: element.textColor,
              },
            });
          } else {
            // Has Question or Not
            setHasQuestion(false);

            // Lucky Wheel Stylesheet Added Here
            arr.push({
              option: element.score,
              style: {
                backgroundColor: element.color,
                textColor: element.textColor,
              },
            });
          }
        });

        // Set Wheel Data State
        setWheelData(arr);
      }
    });

    // Lucky Wheel Data's Added Here
    if (wheelData && wheelData.length > 0) {
      wheelData.forEach((elem, index) => {
        if (index === prizeNumber) {
          console.log(elem.option);

          // Set Wheel Data State
          setWheelWin(elem.option);
        }
      });
    }

    // Lucky Wheel - Control
    dispatch(gamesAction.getLuckyWheelControl());

    // Disable Lucky Wheel Spinning
    setMustSpin(false);
    setSpinned(true);

    // LocalStorage PrizeNumber & Spin Duration
    localStorage.setItem("prizeNumber", prizeNumber);

    // Lucky Wheel Music Stopped
    audio.pause();
  };

  // Lucky Wheel Stop Spinning
  const handleStopSpinning = () => {
    handleStartGame();
  };

  // Question Answer
  const handleSubmit = (event) => {
    event.preventDefault();

    setBtnDisabled(true);

    event.target.elements.answer.forEach((element) => {
      if (element.checked) {
        // Lucky Wheel - Save Reward
        dispatch(
          gamesAction.setLuckyWheelReward(
            luckyWheel.id,
            luckyWheel.luckyWheelType,
            parseInt(element.value)
          )
        )
          .then((res) => {
            // Success
            if (res.isHttpSuccess) {
              setQuestionWheelWin(res.data);

              let message = res.data.responseText;
              message = message.replace('{point}', res.data.score);
              setResponseMessage(message);
              setPoint(res.data.score);

              setIsFinish(true);

              setTimeout(() => {
                dispatch(profileAction.getLoginUserDetail());
              }, 2000);

              if (res.data?.answerTrue === true) {
                setIsCorrectAnswer(true);
              }

              if (res.data?.answerFalse === true) {
                setIsCorrectAnswer(false);
              }
            }
          })
          .catch((err) => {
            // Error
            if (err.isHttpError) {
              setQuestionWheelWin(null);
              setQuestionError(true);
            }
          });

        // Question is answered
        setAnswered(true);
        setRightOfAnswer(0);
      }
    });
  };

  // Lucky Wheel Begin Game
  const handleBeginGame = (e) => {
    e.preventDefault();

    setBeginGame(true);

    if (wheelData) {
      setTimeout(() => {
        const triangle = document.querySelectorAll(".sc-bdfBwQ");
        if (triangle.length > 0) {
          triangle[0].src = Triangle;
        }
      }, 100);
    }
  };

  // Lucky Wheel - When you come to the second time page
  useEffect(() => {
    const prize = localStorage.getItem("prizeNumber");

    if (prize) {
      setPrizeNumber(prizeNumber);
      setSpinned(false);
      setStartIndex(prizeNumber);
    }

    return () => {};
  }, []);

  // Audio
  useEffect(() => {
    const audio = new Audio(Roulette);
    setAudio(audio);

    return () => {
      audio.pause();
      audio.src = "";
    };
  }, []);

  useEffect(() => {
    // Lucky Wheel - Get
    dispatch(gamesAction.getLuckyWheel());
    // Lucky Wheel - Control
    dispatch(gamesAction.getLuckyWheelControl());

    return () => {};
  }, [dispatch]);

  // Lucky Wheel's Info Added
  useEffect(() => {
    if (luckyWheel && luckyWheel.wheelPartDTOList) {
      let arr = [];
      let percentage = [];

      luckyWheel.wheelPartDTOList.forEach((element) => {
        percentage.push(element.percentage);
        arr.push({
          option: element.text,
          style: {
            backgroundColor: element.color,
            textColor: element.textColor,
          },
        });
      });

      // Prize Number Calculate With Percentage
      if (!prizeNumber) {
        // 1-100 Range Random Number
        let random = Math.floor(Math.random() * 100) + 1;

        // Percentage Calculate
        percentage.forEach((percent, index) => {
          if (index === 0) {
            if (random <= percent) {
              setPrizeNumber(index);
            }
          } else {
            const percentageArr = [];

            for (let i = 0; i < percentage.length; i++) {
              if (i === 0) {
                percentageArr.push(percentage[i]);
              } else {
                percentageArr.push(percentage[i] + percentageArr[i - 1]);
              }
            }

            if (
              random > percentageArr[index - 1] &&
              random <= percentageArr[index]
            ) {
              setPrizeNumber(index);
            }
          }
        });
      }

      setWheelData(arr);
    }
    return () => {};
  }, [luckyWheel]);

  // Lucky Wheel Control
  useEffect(() => {
    if (luckyWheelControl) {
      if (
        luckyWheelControl.gameIsOn === true &&
        luckyWheelControl.remainTime === 0
      ) {
        setLuckyWheelError(true);
      } else if (
        luckyWheelControl.gameIsOn === false &&
        luckyWheelControl.remainTime === 0
      ) {
        setLuckyWheelError(true);
      } else {
        setLuckyWheelError(false);
      }
    }

    return () => {};
  }, [luckyWheelControl]);

  // Lucky Wheel Wheel Data Check
  useEffect(() => {
    if (wheelData) {
      setTimeout(() => {
        const triangle = document.querySelectorAll(".sc-bdfBwQ");
        if (triangle.length > 0) {
          triangle[0].src = Triangle;
        }
      }, 100);
    }

    return () => {};
  }, [wheelData]);

  return (
    <>
      <main className="main-container">
        <Header />
        <section
          className="games-page page-container wheel-page"
          id="backgroundPattern"
        >
          <div
            className="games-page-introduction"
            style={{ backgroundImage: `url(${BgDots})` }}
            id="backgroundImage"
          >
            {luckyWheelStart && (
              <div
                className={
                  answered ? "d-none game-timer" : "d-block game-timer"
                }
              >
                <h3 className="text-center">Kalan Süre</h3>
                <div className="game-countdown text-center ml-auto mr-auto">
                  {luckyWheel?.remainTime && luckyWheelControl?.remainTime && (
                    <CountdownCircleTimer
                      isPlaying
                      duration={luckyWheel?.remainTime}
                      initialRemainingTime={luckyWheelControl?.remainTime}
                      colors={timerColors}
                      colorsTime={timerColorsTime}
                      size={80}
                      strokeWidth={8}
                      onComplete={() => handleTimerComplete()}
                    >
                      {({ remainingTime }) => remainingTime}
                    </CountdownCircleTimer>
                  )}
                </div>

                <h3>Cevap Hakkı</h3>
                <p className="text-center">{rightOfAnswer}</p>
              </div>
            )}

            <Container>
              {!beginGame && (
                <Row className="justify-content-md-center text-center text-white">
                  <Col xs="12" sm="12" md="8" lg="6" xl="4">
                    {luckyWheel && (
                      <div className="games-page-circle">
                        <img src={Banner} alt="" />
                      </div>
                    )}

                    {luckyWheel && luckyWheel.title ? (
                      <h1 className="mt-5 mb-0 p-0">{luckyWheel.title}</h1>
                    ) : (
                      <h1 className="mt-5 mb-0 p-0">Şans Çarkı</h1>
                    )}

                    {luckyWheel && luckyWheel.description ? (
                      <p className="mt-3 mb-0">{luckyWheel.description}</p>
                    ) : (
                      <p className="mt-3 mb-0">
                        Şans çarkı oyununa hoş geldiniz.
                      </p>
                    )}
                    {luckyWheel ? (
                      <>
                        {luckyWheelError ? (
                          <div className="mt-3">
                            Daha önceden yarışmaya katıldınız. Lütfen daha sonra
                            tekrar deneyiniz.
                          </div>
                        ) : (
                          <Button
                            onClick={(e) => handleBeginGame(e)}
                            className="btn btn-success text-white mt-5 mb-0"
                          >
                            {luckyWheel.buttonText}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>Lütfen Şans Çarkı oyun gününde tekrar deneyiniz.</>
                    )}
                  </Col>
                </Row>
              )}

              {luckyWheel &&
                luckyWheel.luckyWheelType &&
                luckyWheel.luckyWheelType === "SCORE" &&
                beginGame && (
                  <>
                    {!answered && (
                      <Row className="justify-content-md-center mb-5">
                        <Col
                          xs={12}
                          md={10}
                          className="text-center position-static"
                        >
                          <h1 className="wheel-page-title">Soru Çarkı</h1>
                          <p className="wheel-page-desc">
                            4 soru kategorisinden rastgele birisini seçmek için
                            çarkı çevir butonuna basın.
                          </p>
                        </Col>
                      </Row>
                    )}

                    <Row className="justify-content-md-center">
                      <Col xs={12} md={12} lg={6}>
                        {luckyWheelStart && luckyWheelStart.wheelPartDTOList ? (
                          <div className="wheel-spin">
                            <Wheel
                              mustStartSpinning={mustSpin}
                              prizeNumber={Math.floor(
                                Math.random() * (wheelData.length + 1)
                              )}
                              data={wheelData}
                              backgroundColors={["#3e3e3e", "#df3428"]}
                              textColors={["#ffffff"]}
                              outerBorderWidth={0}
                              innerBorderWidth={2}
                              innerBorderColor={["#fff"]}
                              radiusLineColor={"#fff"}
                              radiusLineWidth={2}
                              textDistance={60}
                              perpendicularText={true}
                              onStopSpinning={() => handleStopSpinning()}
                              spinDuration={1}
                            />
                            <div className="text-center wheel-spin-button">
                              <button
                                onClick={handleSpinClick}
                                disabled={spinned === true}
                                className="btn btn-success text-center"
                              >
                                Çevir
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center text-white">
                            <h1>Soru Çarkı Yükleniyor...</h1>
                          </div>
                        )}
                      </Col>

                      <Col
                        xs={12}
                        md={12}
                        lg={6}
                        className={wheelWin ? "wheelWin active" : "wheelWin"}
                      >
                        <h1>Tebrikler Kazandınız!</h1>
                        <p className="!m-0">{responseMessage}</p>
                        
                        <div
                          className={
                            wheelWin
                              ? "wrapper-reward active"
                              : "wrapper-reward"
                          }
                        >
                          <img src={Girls} alt="" />
                          <h4>{point}</h4>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

              {luckyWheel &&
                luckyWheel.luckyWheelType &&
                luckyWheel.luckyWheelType === "QUESTION" &&
                beginGame && (
                  <>
                    {!answered && (
                      <Row className="justify-content-md-center mb-5">
                        <Col
                          xs={12}
                          md={10}
                          className="text-center position-static"
                        >
                          <h1 className="wheel-page-title">Soru Çarkı</h1>
                          <p className="wheel-page-desc">
                            Çarkı çevir gelen kategorideki soruyu doğru bil
                            puanı kap.
                          </p>
                        </Col>
                      </Row>
                    )}

                    <Row className="justify-content-md-center align-items-center question-wrap-row">
                      <Col
                        xs={12}
                        md={12}
                        lg={6}
                        className={answered ? "d-none" : "d-block"}
                      >
                        {luckyWheel && luckyWheel.wheelPartDTOList ? (
                          <div className="wheel-spin">
                            <Wheel
                              mustStartSpinning={mustSpin}
                              prizeNumber={prizeNumber}
                              data={wheelData}
                              backgroundColors={["#3e3e3e", "#df3428"]}
                              textColors={["#ffffff"]}
                              outerBorderWidth={0}
                              innerBorderWidth={2}
                              innerBorderColor={["#fff"]}
                              radiusLineColor={"#fff"}
                              radiusLineWidth={2}
                              textDistance={60}
                              perpendicularText={true}
                              onStopSpinning={() => handleStopSpinning()}
                              spinDuration={1}
                            />
                            <div className="text-center wheel-spin-button">
                              <button
                                onClick={handleSpinClick}
                                disabled={spinned === true}
                                className="btn btn-success text-center"
                              >
                                Çevir
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center text-white">
                            <h1>Soru Çarkı Yükleniyor...</h1>
                          </div>
                        )}
                      </Col>

                      {!answered && (
                        <Col
                          xs={12}
                          md={12}
                          lg={6}
                          className={wheelWin ? "wheelWin active" : "wheelWin"}
                        >
                          <div
                            className={
                              wheelWin
                                ? "wrapper-reward active"
                                : "wrapper-reward"
                            }
                          >
                            <Col
                              xs={12}
                              md={12}
                              className="align-items-center d-flex"
                            >
                              <div className="games-page-question">
                                {question &&
                                  question.length > 0 &&
                                  question.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <h3>{item.text}</h3>
                                        <Form onSubmit={handleSubmit}>
                                          <Form.Group as={Row}>
                                            {item
                                              .luckyWheelQuestionDTOList[0] &&
                                              item.luckyWheelQuestionDTOList[0]
                                                .description && (
                                                <Form.Label
                                                  as="legend"
                                                  column
                                                  sm={12}
                                                  style={{ fontSize: "24px" }}
                                                >
                                                  {
                                                    item
                                                      .luckyWheelQuestionDTOList[0]
                                                      .description
                                                  }
                                                </Form.Label>
                                              )}

                                            <Col sm={10} className="mb-3">
                                              {item
                                                .luckyWheelQuestionDTOList[0] &&
                                                item
                                                  .luckyWheelQuestionDTOList[0]
                                                  .luckyWheelChoiceDTOList &&
                                                item
                                                  .luckyWheelQuestionDTOList[0]
                                                  .luckyWheelChoiceDTOList
                                                  .length > 0 &&
                                                item.luckyWheelQuestionDTOList[0].luckyWheelChoiceDTOList.map(
                                                  (answer, aIndex) => {
                                                    return (
                                                      <Form.Check
                                                        key={aIndex}
                                                        type="radio"
                                                        label={
                                                          answer.description
                                                        }
                                                        name={"answer"}
                                                        value={answer.id}
                                                        id={answer.id}
                                                      />
                                                    );
                                                  }
                                                )}
                                            </Col>
                                          </Form.Group>
                                          <Button
                                            variant="primary"
                                            className="btn btn-success text-white"
                                            type="submit"
                                            disabled={btnDisabled}
                                          >
                                            Cevapla
                                          </Button>
                                        </Form>
                                      </div>
                                    );
                                  })}
                              </div>
                            </Col>
                          </div>
                        </Col>
                      )}

                      <Col
                        xs={12}
                        md={12}
                        lg={6}
                        className={
                          questionWheelWin || questionError
                            ? "wheelWin questionWheelWin active text-center"
                            : "wheelWin questionWheelWin text-center"
                        }
                      >
                        {isCorrectAnswer === true && isFinish === true && (
                          <>
                            <h1>Tebrikler Kazandınız!</h1>
                            <p className="!m-0">{responseMessage}</p>

                            <div
                              className={
                                questionWheelWin || questionError
                                  ? "wrapper-reward active"
                                  : "wrapper-reward"
                              }
                            >
                              <img src={Girls} alt="" />
                              <h4>{point}</h4>
                            </div>
                          </>
                        )}

                        {isCorrectAnswer === false && isFinish === true && (
                          <>
                            <h1>Üzgünüz, Kazanamadınız!</h1>
                            <p className="!m-0">{responseMessage}</p>

                            <div
                              className={
                                questionWheelWin || questionError
                                  ? "wrapper-reward active"
                                  : "wrapper-reward"
                              }
                            >
                              <img src={Girls} alt="" />
                              <h4>0</h4>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
            </Container>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default GameQuestionWheel;
