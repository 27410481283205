import * as profileTypes from "./profile.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

// /employee/loginUser/detail
export const getLoginUserDetail = () => async dispatch => {
  dispatch({ type: profileTypes.GET_LOGIN_USER_DETAIL_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/loginUser/detail`);
    dispatch({
      type: profileTypes.GET_LOGIN_USER_DETAIL_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_LOGIN_USER_DETAIL_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Country - Türkiye
export const getAddressCountry = () => async dispatch => {
  dispatch({ type: profileTypes.GET_COUNTRY_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/employee/address/countryList`
    );
    dispatch({
      type: profileTypes.GET_COUNTRY_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_COUNTRY_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Province - İstanbul
export const getAddressProvince = countryId => async dispatch => {
  dispatch({ type: profileTypes.GET_PROVINCE_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/employee/provinceList/${countryId}`
    );
    dispatch({
      type: profileTypes.GET_PROVINCE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PROVINCE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// County - Ataşehir
export const getAddressCounty = provinceId => async dispatch => {
  dispatch({ type: profileTypes.GET_COUNTY_PENDING });
  console.log("provinceId: ", provinceId);

  try {
    const response = await API.get(
      `${REQUEST_URL}/employee/countyList/${provinceId}`
    );
    dispatch({
      type: profileTypes.GET_COUNTY_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_COUNTY_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// County - Null
export const resetAddressCounty = () => async dispatch => {
  dispatch({ type: profileTypes.GET_COUNTY_PENDING });

  try {
    dispatch({
      type: profileTypes.GET_COUNTY_FULFILLED,
      payload: null
    });
    return Promise.resolve(null);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_COUNTY_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// District - Barbaros
export const getAddressDistrict = countyId => async dispatch => {
  dispatch({ type: profileTypes.GET_DISTRICT_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/employee/districtList/${countyId}`
    );
    dispatch({
      type: profileTypes.GET_DISTRICT_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_DISTRICT_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// District - Null
export const resetAddressDistrict = () => async dispatch => {
  dispatch({ type: profileTypes.GET_DISTRICT_PENDING });

  try {
    dispatch({
      type: profileTypes.GET_DISTRICT_FULFILLED,
      payload: null
    });
    return Promise.resolve(null);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_DISTRICT_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};


// Get Address
export const getAddress = () => async dispatch => {
  dispatch({ type: profileTypes.GET_ADDRESSES_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/address`);
    dispatch({
      type: profileTypes.GET_ADDRESSES_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_ADDRESSES_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Add Address & Edit Address
export const addAddress = ({
  name,
  country,
  province,
  county,
  district,
  street,
  apartmentNo,
  flatNo,
  addressId
}) => async dispatch => {
  dispatch({ type: profileTypes.SET_ADDRESS_PENDING });
  let data = null;

  if (addressId) {
    // Edit
    data = {
      name: name,
      countryId: country,
      provinceId: province,
      countyId: county,
      districtId: district,
      street: street,
      apartmentNo: apartmentNo,
      flatNo: flatNo,
      addressId: addressId,
      orderAddress: false,
    };
  } else {
    data = {
      name: name,
      countryId: country,
      provinceId: province,
      countyId: county,
      districtId: district,
      street: street,
      apartmentNo: apartmentNo,
      flatNo: flatNo,
      orderAddress: false,
    };
  }
  try {
    const response = await API.post(
      `${REQUEST_URL}/employee/address/save`,
      data
    );
    dispatch({ type: profileTypes.SET_ADDRESS_FULFILLED, payload: response });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: profileTypes.SET_ADDRESS_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

// Remove Address
export const deleteAddress = addressId => async dispatch => {
  dispatch({ type: profileTypes.DELETE_ADDRESS_PENDING });

  try {
    const response = await API.post(
      `${REQUEST_URL}/employee/address/delete/${addressId}`
    );
    dispatch({
      type: profileTypes.DELETE_ADDRESS_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.DELETE_ADDRESS_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Change Address Status - Order Address Selection
export const changeAddressStatus = addressId => async dispatch => {
  dispatch({ type: profileTypes.SET_ADDRESS_STATUS_PENDING });

  try {
    const response = await API.post(
      `${REQUEST_URL}/employee/address/orderAddress/${addressId}`
    );
    dispatch({
      type: profileTypes.SET_ADDRESS_STATUS_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.SET_ADDRESS_STATUS_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile - Messages Page Get SubjectList
export const getSubjectList = () => async dispatch => {
  dispatch({ type: profileTypes.GET_SUBJECT_LIST_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/profile/contactSubjectList`);
    dispatch({
      type: profileTypes.GET_SUBJECT_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_SUBJECT_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile - Messages Page Get SubjectList
export const getSubjectById = subjectId => async dispatch => {
  dispatch({ type: profileTypes.GET_SUBJECT_BY_ID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/profile/contactMessageList/${subjectId}`
    );
    dispatch({
      type: profileTypes.GET_SUBJECT_BY_ID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_SUBJECT_BY_ID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile - Get Page Description
export const getPageDescription = type => async dispatch => {
  dispatch({ type: profileTypes.GET_PROFILE_DESCRIPTION_PENDING });

  try {  
    const response = await API.get(`${REQUEST_URL}/profile/pageDescription?type=${type}`);
  
    dispatch({
      type: profileTypes.GET_PROFILE_DESCRIPTION_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PROFILE_DESCRIPTION_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile - Write Us - Send Message
export const sendMessage = (message, contactSubjectText, file) => async dispatch => {
  dispatch({ type: profileTypes.SET_SEND_MESSAGE_PENDING });

  // FormData nesnesi oluşturma
  const formData = new FormData();

  // contactMessageDTO objesini oluşturma
  const contactMessageDTO = {
    message: message || "", // Eğer message varsa veya boş bir string ise, ekleyin
    contactSubjectText: contactSubjectText.trim() || "", // Eğer contactSubjectText varsa veya boş bir string ise, ekleyin
  };

  // contactMessageDTO'yu JSON formatında formData'ya ekleyin
  formData.append('contactMessageDTO', JSON.stringify(contactMessageDTO));

  if (file instanceof File) {
    formData.append('file', file);
  }

  try {
    const response = await API.post(
      `${REQUEST_URL}/profile/contactMessageInitial/save`,
      formData,
    );
    dispatch({
      type: profileTypes.SET_SEND_MESSAGE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.SET_SEND_MESSAGE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Send Message By Id
export const sendMessageById = (contactSubjectId, message, file) => async dispatch => {
  dispatch({ type: profileTypes.SEND_MESSAGE_BY_ID_PENDING });

  // FormData nesnesi oluşturma
  const formData = new FormData();

  // contactMessageDTO objesini oluşturma
  const contactMessageDTO = {
    contactSubjectId: contactSubjectId,
    message: message || "", // Eğer message varsa veya boş bir string ise, ekleyin
  };

  // contactMessageDTO'yu JSON formatında formData'ya ekleyin
  formData.append('contactMessageDTO', JSON.stringify(contactMessageDTO));

  if (file instanceof File) {
    formData.append('file', file);
  }

  try {
    const response = await API.post(
      `${REQUEST_URL}/profile/contactMessage/save`,
      formData,
    );
    dispatch({
      type: profileTypes.SEND_MESSAGE_BY_ID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.SEND_MESSAGE_BY_ID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile Change Password
export const profileChangePassword = (
  newPass,
  newPassConfirm,
  changePassType
) => async dispatch => {
  dispatch({ type: profileTypes.SET_CHANGE_PASSWORD_PENDING });

  const data = {
    userName: localStorage.getItem("username"),
    tenantName: "tenant1",
    newPass: newPass,
    newPassConfirm: newPassConfirm,
    changePassType: changePassType
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/auth/saveNewPassword`,
      data
    );
    dispatch({
      type: profileTypes.SET_CHANGE_PASSWORD_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.SET_CHANGE_PASSWORD_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile Image Upload By Type (PROFILE / SCREEN / OTHER)
export const profileImageUpload = selectedChoice => async dispatch => {
  dispatch({ type: profileTypes.SET_PROFILE_IMAGE_UPLOAD_PENDING });

  const data = {
    selectedChoice: selectedChoice
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/content/upload/employee/image`,
      data
    );
    dispatch({
      type: profileTypes.SET_PROFILE_IMAGE_UPLOAD_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.SET_PROFILE_IMAGE_UPLOAD_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile - Point History Page - User's Available Points
export const getScore = (page = 0, size = 0) => async dispatch => {
  dispatch({ type: profileTypes.GET_USERS_SCORE_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/score/get/all/available/score?page=${page}&size=${size}`
    );

    dispatch({
      type: profileTypes.GET_USERS_SCORE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_USERS_SCORE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile - Point History Page - Get Score History All
export const getProfileAllPointHistory = (page=0, size=2000) => async dispatch => {
  dispatch({ type: profileTypes.GET_PROFILE_SCORE_HISTORY_ALL_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/score/scoreHistoryAll?page=${page}&size=${size}`
    );

    dispatch({
      type: profileTypes.GET_PROFILE_SCORE_HISTORY_ALL_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PROFILE_SCORE_HISTORY_ALL_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile - Point History Page - Get Collected Points
export const getProfileCollectedPoints = (page, size) => async dispatch => {
  dispatch({ type: profileTypes.GET_PROFILE_SCORE_HISTORY_COLLECTED_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/score/scoreHistoryCollect?page=${page}&size=${size}`
    );
    dispatch({
      type: profileTypes.GET_PROFILE_SCORE_HISTORY_COLLECTED_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PROFILE_SCORE_HISTORY_COLLECTED_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Profile - Point History Page - Get Spent Points
export const getProfileSpentPoints = (page, size) => async dispatch => {
  dispatch({ type: profileTypes.GET_PROFILE_SCORE_HISTORY_SPENT_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/score/scoreHistorySpent?page=${page}&size=${size}`
    );
    dispatch({
      type: profileTypes.GET_PROFILE_SCORE_HISTORY_SPENT_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PROFILE_SCORE_HISTORY_SPENT_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Get Profile User Image
export const getProfilePhoto = () => async dispatch => {
  dispatch({ type: profileTypes.GET_PROFILE_USER_IMAGE_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/profile/employeePhotoList`,
      config
    );
    dispatch({
      type: profileTypes.GET_PROFILE_USER_IMAGE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PROFILE_USER_IMAGE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const removeImageById = id => async dispatch => {
  dispatch({ type: profileTypes.REMOVE_IMAGE_PENDING });

  try {
    const response = await API.post(
      `${REQUEST_URL}/profile/delete/${id}`,
      config
    );
    dispatch({
      type: profileTypes.REMOVE_IMAGE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.REMOVE_IMAGE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const imageUpload = (
  file,
  selectedChoice
) => async dispatch => {
  dispatch({ type: profileTypes.IMAGE_UPLOAD_PENDING });

  const data = new FormData();
  data.append("file", file);
  data.append("contentType", selectedChoice);
  // data.append("usePermission", usePermission);

  try {
    const response = await API.post(
      `${REQUEST_URL}/content/upload/employee/image`,
      data
    );
    dispatch({
      type: profileTypes.IMAGE_UPLOAD_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.IMAGE_UPLOAD_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Get Order List
export const getOrderList = () => async dispatch => {
  dispatch({ type: profileTypes.GET_ORDER_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/order/orderList?page=0&size=1000&first=false&last=true`,
      config
    );
    dispatch({
      type: profileTypes.GET_ORDER_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_ORDER_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Get Tenant Menu
export const getTenantMenu = (id) => async dispatch => {
  dispatch({ type: profileTypes.GET_TENANT_MENU_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/auth/getTenantMenu/${id}`,
      config
    );
    dispatch({
      type: profileTypes.GET_TENANT_MENU_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_TENANT_MENU_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getMonthlyPerformance = () => async dispatch => {
  dispatch({
    type: profileTypes.GET_MONTHLY_PERFORMANCE_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/monthlyBuy`, config);

    dispatch({
      type: profileTypes.GET_MONTHLY_PERFORMANCE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_MONTHLY_PERFORMANCE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getMonthlyIncomePerformance = () => async dispatch => {
  dispatch({
    type: profileTypes.GET_MONTHLY_INCOME_PERFORMANCE_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/monthlyIncome`, config);

    dispatch({
      type: profileTypes.GET_MONTHLY_INCOME_PERFORMANCE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_MONTHLY_INCOME_PERFORMANCE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getProductFoundPerformance = () => async dispatch => {
  dispatch({
    type: profileTypes.GET_PRODUCT_FOUND_PERFORMANCE_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/productFound`, config);

    dispatch({
      type: profileTypes.GET_PRODUCT_FOUND_PERFORMANCE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PRODUCT_FOUND_PERFORMANCE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getPercentagePerformance = () => async dispatch => {
  dispatch({
    type: profileTypes.GET_PERCENTAGE_PERFORMANCE_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/percentage/buy`, config);

    dispatch({
      type: profileTypes.GET_PERCENTAGE_PERFORMANCE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PERCENTAGE_PERFORMANCE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getFocusInfoPerformance = () => async dispatch => {
  dispatch({
    type: profileTypes.GET_FOCUS_INFO_PERFORMANCE_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/focusInfo`, config);

    dispatch({
      type: profileTypes.GET_FOCUS_INFO_PERFORMANCE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_FOCUS_INFO_PERFORMANCE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};